




































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  GetDaohang,
  AddDaohang,
  EditDaohang,
  DelDaohang,
  SortDaohang,
} from "@/request/schema";
@Component({})
export default class Name extends Vue {
  private currentNode: any = {};
  private expandedNode: any = [];
  private id: any = 1000;
  private data: any = [
    {
      id: 1,
      label: "事物",
      children: [
        {
          id: 4,
          label: "物体",
          children: [
            {
              id: 9,
              label: "概念性物体",
              children: [
                {
                  id: 11,
                  label: "中医基础理论",
                  children: [
                    {
                      id: 13,
                      label: "阴阳学说",
                    },
                    {
                      id: 14,
                      label: "五行学说",
                    },
                  ],
                },
                {
                  id: 12,
                  label: "沿革地理",
                },
              ],
            },
            {
              id: 10,
              label: "实质性物体",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      label: "事件",
      children: [
        {
          id: 5,
          label: "活动",
        },
        {
          id: 6,
          label: "现象或过程",
        },
      ],
    },
  ];
  private defaultProps: any = {
    label: "域名称",
  };
  private addJiegouData: any = {};
  private modifyData: any = {};
  private ifShowAdd: any = false;
  private ifModify: any = false;
  private clickData: any = {}; // 点击的数据
  private add(data: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      type: "语义类型",
      name: data.name,
    };
    AddDaohang(this, params, loading).then((res: any) => {
      this.$message.success("添加成功！");
      loading.close();
      this.getNav();
    });
  }
  private refresh(d: any) {
    this.currentNode = {
      域id: d._id,
      域名称: d["名称"],
      类型: d["类型"],
    };
    this.getNav().then((res: any) => {
      this.init();
    });
  }
  private addSure() {
    if (!this.addJiegouData.name) {
      this.$message.warning("请输入语义类型名称！");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      type: "语义类型",
      name: this.addJiegouData.name,
      域id: this.clickData["域id"],
    };
    AddDaohang(this, params, loading).then((res: any) => {
      this.$message.success("添加成功！");
      loading.close();
      this.getNav();
      this.ifShowAdd = false;
    });
  }
  private append(data: any) {
    this.clickData = data;
    this.ifShowAdd = true;
    this.addJiegouData = {};
  }
  private handleCommand(e: any) {
    this.clickData = e.data;
    if (e.command == "重命名") {
      this.modifyData = {
        name: this.currentNode["域名称"],
      };
      this.ifModify = true;
    } else if (e.command == "删除") {
      this.$confirm(
        "您确定要删除该语义类型么？删除后该语义类型下的子结构也将全部删除。",
        "删除",
        {
          customClass: "commonConfirm",
        }
      )
        .then(() => {
          const params: any = {
            data: {
              _id: this.clickData["域id"],
            },
          };
          const loading = this.$loading({
            lock: true,
            text: "加载中……",
            spinner: "el-icon-loading",
            customClass: "loadClass",
            background: "rgba(255, 255, 255, 0.7)",
          });
          DelDaohang(this, params, loading).then((res: any) => {
            loading.close();
            this.$message.success("删除成功！");
            this.getNav().then((d: any) => {
              this.currentNode = this.data[0];
              this.init();
            });
          });
        })
        .catch(() => {
          console.log("取消");
        });
    }
  }
  private modify() {
    if (!this.modifyData.name) {
      this.$message.warning("请输入语义类型名称！");
      return;
    }
    const params: any = {
      _id: this.clickData["域id"],
      name: this.modifyData.name,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    EditDaohang(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("重命名成功");
      this.ifModify = false;
      this.getNav().then((d: any) => {
        this.currentNode = {
          域id: this.currentNode["域id"],
          域名称: this.modifyData.name,
        };
        this.$emit("updateCurrentTree", this.currentNode);
        this.init();
      });
    });
  }
  // 拖拽成功完成时触发的事件
  private handleDrop(draggingNode: any, dropNode: any, dropType: any, ev: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      data: this.data,
    };
    SortDaohang(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("移动成功！");
      this.getNav().then((d: any) => {
        this.currentNode = draggingNode.data;
        this.init();
      });
    });
  }
  private handleNodeClick(data: any, node: any) {
    this.currentNode = data;
    this.init();
  }
  private init() {
    console.log("初始化");
    if (this.$refs.yuyiTree) {
      (this.$refs.yuyiTree as any).setCurrentKey(this.currentNode);
      this.expandedNode = [this.currentNode["域id"]];
      this.$emit("updateCurrentTree", this.currentNode);
    }
  }
  private getNav() {
    return new Promise((resolve, reject) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        params: {
          type: "语义类型",
        },
      };
      GetDaohang(this, params, loading).then((res: any) => {
        loading.close();
        this.data = res;
        resolve(res);
      });
    });
  }
  private mounted() {
    if (this.$route.query.data) {
      const d = JSON.parse(this.$route.query.data as any);
      if (d["类型"] == "语义类型") {
        this.getNav().then((data: any) => {
          this.currentNode = {
            域id: d._id,
            域名称: d["名称"],
            类型: d["类型"],
          };
          this.init();
        });
      } else {
        this.getNav().then((d: any) => {
          this.currentNode = this.data[0];
          this.init();
        });
      }
    } else {
      this.getNav().then((d: any) => {
        if (!this.currentNode["域id"]) {
          this.currentNode = this.data[0];
          this.init();
        }
      });
    }
  }
}
