import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 搜索 语义类型/属性类型/关系类型/取值类型 可选项
 * @param vCom Vue组件
 */
const SearchDaohang = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/schema/type", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 查询 语义类型/属性类型/关系类型
 * @param vCom Vue组件
 */
const GetDaohang = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/schema", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 新增 语义类型/属性类型/关系类型
 * @param vCom Vue组件
 */
const AddDaohang = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/schema", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 编辑 语义类型/属性类型/关系类型
 * @param vCom Vue组件
 */
const EditDaohang = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.put("api/annotator/schema", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 删除 语义类型/属性类型/关系类型
 * @param vCom Vue组件
 */
const DelDaohang = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete("api/annotator/schema", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 移动排序 属性类型/关系类型
 * @param vCom Vue组件
 */
const MoveDaohang = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/schema/sort", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 移动排序 语义类型
 * @param vCom Vue组件
 */
const SortDaohang = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/schema/semantic/sort", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取详情 属性/关系类型的定义,类别,反向关系及用于的取值类型
 * @param vCom Vue组件
 */
const GetDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/schema/define", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 编辑 语义/属性/关系类型的定义,类别,反向关系
 */
const EditDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.put("api/annotator/schema/define", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 删除 属性类型-取值类型
 */
const DelShuxingQuzhi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete("api/annotator/schema/define", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 属性类型/关系类型-取值类型保存
 */
const SaveQuzhi = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/annotator/schema/define", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 查看语义类型下的属性类型或关系类型
 */
const GetSemantic = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/schema/semantic", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 删除 语义类型下的属性类型或关系类型
 */
const DelSemantic = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete("api/annotator/schema/semantic", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取类别选项
 */
const GetCategory = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/schema/category").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 搜索
 */
const Search = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/annotator/schema/search", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
export {
    SearchDaohang, GetDaohang, AddDaohang, EditDaohang, DelDaohang, MoveDaohang,
    SortDaohang, GetDetail, EditDetail, DelShuxingQuzhi, SaveQuzhi,
    GetSemantic, DelSemantic, GetCategory, Search
}