










































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  GetDetail,
  EditDetail,
  DelShuxingQuzhi,
  SearchDaohang,
  SaveQuzhi,
  GetCategory,
} from "@/request/schema";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private currentTree: any;
  @Watch("currentTree", { immediate: true })
  private currentTreeChange() {
    if (this.currentTree._id) {
      this.getData();
    }
  }
  private data: any = {
    data: [],
  };
  private addText: any = "";
  private text: any = ""; // 添加、编辑弹框标题
  private addType: any = ""; // 定义、类别、反向关系
  private ifShowDingyi: any = false;
  private ifShowAdd: any = false;
  private editData: any = {
    类型: {},
    取值类型: [],
  };
  private options: any = [];
  private quzhis: any = [];
  private category: any = [];
  private goShiti(item: any) {
    const obj = JSON.parse(JSON.stringify(item));
    obj["类型"] = "语义类型";
    this.$emit("refresh", obj);
    this.$router.replace({
      path: "/main/mark/schema/folder?t=" + new Date().getTime(),
    });
  }
  private openAddLeixing() {
    this.editData = {
      类型: {},
      取值类型: [],
    };
    this.ifShowAdd = true;
    this.remoteMethod("");
    this.remoteQuzhiMethod("");
  }
  private addSure() {
    if (!this.editData["类型"]._id) {
      this.$message.warning("请选择类型");
      return;
    }
    if (this.editData["取值类型"].length == 0) {
      this.$message.warning("请选择对应的取值类型");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      _id: this.currentTree._id,
      name: this.currentTree["名称"],
      类型: this.editData["类型"],
      取值类型: this.editData["取值类型"],
    };
    SaveQuzhi(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("添加成功！");
      this.ifShowAdd = false;
      this.getData();
    });
  }
  private remoteMethod(e: any) {
    const params: any = {
      params: {
        type: "语义类型",
        search: e,
      },
    };
    SearchDaohang(this, params).then((res: any) => {
      this.options = res;
    });
  }
  private remoteQuzhiMethod(e: any) {
    const params: any = {
      params: {
        type: "语义类型",
        search: e,
      },
    };
    SearchDaohang(this, params).then((res: any) => {
      this.quzhis = res;
    });
  }
  private del(item: any) {
    this.$confirm("您确定要移除该关系类型么？", "移除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const params: any = {
          data: {
            _id: this.currentTree._id,
            类型: item["类型"],
          },
        };
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        DelShuxingQuzhi(this, params, loading).then((res: any) => {
          loading.close();
          this.$message.success("移除成功！");
          this.getData();
        });
      })
      .catch(() => {
        console.log("取消");
      });
  }
  // val 添加/编辑  type 定义/类别/反向关系
  private openAdd(val: any, type: any) {
    this.text = val;
    if (val == "添加") {
      this.addText = "";
    } else {
      this.addText = this.data[type];
    }
    this.addType = type;
    this.ifShowDingyi = true;
    this.getCategory();
  }
  private getCategory() {
    GetCategory(this).then((res: any) => {
      this.category = res;
    });
  }
  private dingyiSure() {
    if (!this.addText) {
      this.$message.warning("请输入" + this.addType);
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      _id: this.currentTree._id,
      define_desc: this.addText,
      field: this.addType,
    };
    EditDetail(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success(this.text + "成功！");
      this.ifShowDingyi = false;
      this.getData();
      if (this.addType == "类别") {
        this.$emit("updateTree");
      }
    });
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        _id: this.currentTree._id,
        type: "关系类型",
      },
    };
    GetDetail(this, params, loading).then((res: any) => {
      loading.close();
      this.data = res;
    });
  }
}
