














































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  GetDaohang,
  AddDaohang,
  EditDaohang,
  DelDaohang,
  MoveDaohang,
} from "@/request/schema";
@Component({})
export default class Name extends Vue {
  private currentNode: any = {};
  private expandedNode: any = [];
  private data: any = [];
  private addJiegouData: any = {};
  private modifyData: any = {};
  private ifModify: any = false;
  private clickData: any = {}; // 点击的数据
  private defaultProps: any = {
    label: "名称",
  };
  private add(data: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      type: "关系类型",
      name: data.name,
      类别: data.category,
    };
    AddDaohang(this, params, loading).then((res: any) => {
      this.$message.success("添加成功！");
      loading.close();
      this.getNav();
    });
  }
  private remove(node: any, data: any) {
    const parent = node.parent;
    const children = parent.data.children || parent.data;
    const index = children.findIndex((d: any) => d.id === data.id);
    children.splice(index, 1);
  }
  private handleCommand(e: any) {
    this.clickData = e.data;
    if (e.command == "重命名") {
      this.modifyData = {
        name: this.currentNode["名称"],
      };
      this.ifModify = true;
    } else if (e.command == "删除") {
      this.$confirm("您确定要删除该关系类型么？", "删除", {
        customClass: "commonConfirm",
      })
        .then(() => {
          const params: any = {
            data: {
              _id: this.clickData._id,
            },
          };
          const loading = this.$loading({
            lock: true,
            text: "加载中……",
            spinner: "el-icon-loading",
            customClass: "loadClass",
            background: "rgba(255, 255, 255, 0.7)",
          });
          DelDaohang(this, params, loading).then((res: any) => {
            loading.close();
            this.$message.success("删除成功！");
            this.getNav().then((d: any) => {
              this.currentNode = this.data[0].children[0];
              this.init();
            });
          });
        })
        .catch(() => {
          console.log("取消");
        });
    }
  }
  private modify() {
    if (!this.modifyData.name) {
      this.$message.warning("请输入关系类型名称！");
      return;
    }
    const params: any = {
      _id: this.clickData._id,
      name: this.modifyData.name,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    EditDaohang(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("重命名成功");
      this.ifModify = false;
      this.getNav().then((d: any) => {
        this.currentNode = {
          _id: this.currentNode["_id"],
          名称: this.modifyData.name,
        };
        this.$emit("updateCurrentTree", this.currentNode);
        this.init();
      });
    });
  }
  // 拖拽成功完成时触发的事件
  private handleDrop(draggingNode: any, dropNode: any, dropType: any, ev: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      data: dropNode.parent.data.children,
    };
    MoveDaohang(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("移动成功！");
      this.getNav().then((d: any) => {
        this.currentNode = draggingNode.data;
        this.init();
      });
    });
  }
  private handleNodeClick(data: any, node: any) {
    if (data._id) {
      this.currentNode = data;
      this.init();
    }
  }
  private init() {
    if (this.$refs.guanxiTree) {
      (this.$refs.guanxiTree as any).setCurrentKey(this.currentNode);
      this.expandedNode = [this.currentNode._id];
      this.$emit("updateCurrentTree", this.currentNode);
    }
  }
  private allowDrop(draggingNode: any, dropNode: any, type: any) {
    if (
      !dropNode.data["_id"] ||
      !draggingNode.data._id ||
      type == "inner" ||
      draggingNode.data["类别"] != dropNode.data["类别"]
    ) {
      return false;
    } else {
      return true;
    }
  }
  private getNav() {
    return new Promise((resolve, reject) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        params: {
          type: "关系类型",
        },
      };
      GetDaohang(this, params, loading).then((res: any) => {
        loading.close();
        res.forEach((ele: any) => {
          ele["名称"] = ele["类别"];
        });
        this.data = res;
        resolve(res);
      });
    });
  }
  private refresh(d: any) {
    this.currentNode = d;
    this.init();
  }
  private updateTree() {
    this.getNav();
  }
  private mounted() {
    if (this.$route.query.data) {
      const d = JSON.parse(this.$route.query.data as any);
      if (d["类型"] == "关系类型") {
        this.getNav().then((data: any) => {
          this.currentNode = d;
          this.init();
        });
      } else {
        this.getNav().then((d: any) => {
          this.currentNode = this.data[0]["children"][0];
          this.init();
        });
      }
    } else {
      this.getNav().then((d: any) => {
        this.currentNode = this.data[0]["children"][0];
        this.init();
      });
    }
  }
}
