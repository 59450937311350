var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-tree',{ref:"guanxiTree",attrs:{"data":_vm.data,"draggable":"","accordion":"","check-on-click-node":"","auto-expand-parent":"","node-key":"_id","props":_vm.defaultProps,"default-expanded-keys":_vm.expandedNode,"allow-drop":_vm.allowDrop},on:{"node-drop":_vm.handleDrop,"node-click":_vm.handleNodeClick},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node",class:{ active: data._id && _vm.currentNode._id == data._id }},[_c('span',{staticClass:"oneline",class:{ second: data._id },attrs:{"title":node.label}},[_vm._v(_vm._s(node.label))]),(data._id && _vm.currentNode._id == data._id)?_c('div',{staticClass:"rightBtn"},[_c('el-dropdown',{on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_c('img',{staticClass:"moreBtn",attrs:{"src":"/common/more.png","alt":""}})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                data: data,
                node: node,
                command: '重命名',
              }}},[_vm._v("重命名")]),_c('el-dropdown-item',{attrs:{"command":{
                data: data,
                node: node,
                command: '删除',
              }}},[_vm._v("删除")])],1)],1)],1):_vm._e()])}}])}),_c('el-dialog',{staticClass:"commonDialog",attrs:{"width":"500px","visible":_vm.ifModify},on:{"update:visible":function($event){_vm.ifModify=$event}}},[_c('button',{staticClass:"close",on:{"click":function($event){_vm.ifModify = false}}}),_c('div',{staticClass:"header"},[_c('span',{staticClass:"title"},[_vm._v("重命名")])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"cell"},[_c('div',{staticClass:"label"},[_vm._v("关系类型:")]),_c('div',{staticClass:"cell-content"},[_c('el-input',{attrs:{"size":"medium","placeholder":"请输入关系类型名称"},model:{value:(_vm.modifyData.name),callback:function ($$v) {_vm.$set(_vm.modifyData, "name", $$v)},expression:"modifyData.name"}})],1)])]),_c('div',{staticClass:"control"},[_c('button',{staticClass:"cancelBtn",on:{"click":function($event){_vm.ifModify = false}}},[_vm._v("取消")]),_c('button',{staticClass:"confirmBtn",on:{"click":_vm.modify}},[_vm._v("确定")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }