





































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import Empty from "@/components/empty.vue";
import {
  GetDetail,
  EditDetail,
  GetSemantic,
  SearchDaohang,
  SaveQuzhi,
  DelSemantic,
  MoveDaohang,
} from "@/request/schema";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends mixins(quanxianMixin) {
  @Prop()
  private currentTree: any;
  @Watch("currentTree", { immediate: true })
  private currentTreeChange() {
    if (this.getIsShowQuanxian("schema属性类型")) {
      this.cTab = "属性类型";
    } else {
      this.cTab = "关系类型";
    }
    if (this.currentTree["域id"]) {
      this.getData();
    }
  }

  private data: any = {};
  private subData: any = [];
  private tree: any = [
    {
      id: 1,
      属性类型: "中文名称",
      值域: "文本",
      定义: "指事物的中文名称",
    },
    {
      id: 2,
      属性类型: "别名",
      值域: "文本",
      定义: "指事物的其他名称",
    },
    {
      id: 3,
      属性类型: "拼音名",
      值域: "文本",
      定义: "指事物的拼音名称",
    },
  ];
  private cTab: any = "";
  private ifShowDingyi: any = false;
  private ifShowAddLeixing: any = false;
  private ifShowAddGuanxi: any = false;
  private dingyi: any = "";
  private addLeixingData: any = {
    关系类型: "",
    属性类型: "",
    取值类型: [],
  };
  private shuxings: any = [];
  private guanxis: any = [];
  private defaultProps: any = {
    label: "属性类型",
  };
  private defaultProps2: any = {
    label: "关系类型",
  };
  private quzhis: any = [];
  private text: any = ""; // 添加、编辑弹框标题
  private allowDrop(draggingNode: any, dropNode: any, type: any) {
    if (type == "inner") {
      return false;
    } else {
      return true;
    }
  }
  private goDetail(item: any, type?: any) {
    item["类型"] = type || this.cTab;
    this.$emit("refresh", item);
    this.$router.replace({
      path: "/main/mark/schema/folder?t=" + new Date().getTime(),
    });
  }
  private changeTab(val: any) {
    this.cTab = val;
    this.getSemantic();
  }
  private openAdd(val: any) {
    this.text = val;
    if (val == "编辑") {
      this.dingyi = this.data["定义"];
    } else {
      this.dingyi = "";
    }
    this.ifShowDingyi = true;
  }
  private dingyiSure() {
    if (!this.dingyi) {
      this.$message.warning("请输入定义描述");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      _id: this.currentTree["域id"],
      define_desc: this.dingyi,
      field: "定义",
    };
    EditDetail(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success(this.text + "成功！");
      this.ifShowDingyi = false;
      this.getData();
    });
  }
  private openAddLeixing() {
    this.addLeixingData = {
      关系类型: "",
      属性类型: "",
      取值类型: [],
    };
    this.shuxings = [];
    this.quzhis = [];
    this.guanxis = [];
    if (this.cTab == "属性类型") {
      this.getQuzhiOptions("数据类型");
      this.remoteShuxingMethod("");
      this.ifShowAddLeixing = true;
    } else if (this.cTab == "关系类型") {
      this.remoteYuyiMethod("");
      this.remoteGuanxiMethod("");
      this.ifShowAddGuanxi = true;
    }
  }
  private remoteShuxingMethod(e: any) {
    const params: any = {
      params: {
        type: "属性类型",
        search: e,
      },
    };
    SearchDaohang(this, params).then((res: any) => {
      this.shuxings = res;
    });
  }
  private remoteGuanxiMethod(e: any) {
    const params: any = {
      params: {
        type: "关系类型",
        search: e,
      },
    };
    SearchDaohang(this, params).then((res: any) => {
      this.guanxis = res;
    });
  }
  private remoteYuyiMethod(e: any) {
    const params: any = {
      params: {
        type: "语义类型",
        search: e,
      },
    };
    SearchDaohang(this, params).then((res: any) => {
      this.quzhis = res;
    });
  }
  private addLeixingSure() {
    if (!this.addLeixingData["属性类型"]) {
      this.$message.warning("请选择属性类型");
      return;
    }
    if (this.addLeixingData["取值类型"].length == 0) {
      this.$message.warning("请选择取值类型");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      _id: this.currentTree["域id"],
      name: this.currentTree["域名称"],
      类型: this.addLeixingData["属性类型"],
      取值类型: this.addLeixingData["取值类型"],
    };
    SaveQuzhi(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("添加成功！");
      this.ifShowAddLeixing = false;
      this.getSemantic();
    });
  }
  private addGuanxiSure() {
    if (!this.addLeixingData["关系类型"]) {
      this.$message.warning("请选择关系类型");
      return;
    }
    if (this.addLeixingData["取值类型"].length == 0) {
      this.$message.warning("请选择取值类型");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      _id: this.currentTree["域id"],
      name: this.currentTree["域名称"],
      类型: this.addLeixingData["关系类型"],
      取值类型: this.addLeixingData["取值类型"],
    };
    SaveQuzhi(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("添加成功！");
      this.ifShowAddGuanxi = false;
      this.getSemantic();
    });
  }
  // 拖拽成功完成时触发的事件
  private handleDrop(draggingNode: any, dropNode: any, dropType: any, ev: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      域id: this.currentTree["域id"],
      data: this.subData[0].result,
    };
    MoveDaohang(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("移动成功！");
      this.getSemantic();
    });
  }
  private delOneLeixing(d: any) {
    this.$confirm("您确定要移除该" + this.cTab + "么？", "移除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const params: any = {
          data: {
            _id: d._id,
            域id: this.currentTree["域id"],
          },
        };
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        DelSemantic(this, params, loading).then((res: any) => {
          loading.close();
          this.$message.success("移除成功！")!;
          this.getSemantic();
        });
      })
      .catch(() => {
        console.log("取消");
      });
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        _id: this.currentTree["域id"],
        type: "语义类型",
      },
    };
    GetDetail(this, params, loading).then((res: any) => {
      loading.close();
      this.data = res;
      this.getSemantic();
    });
  }
  private getSemantic() {
    const params: any = {
      params: {
        _id: this.currentTree["域id"],
        type: this.cTab,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetSemantic(this, params, loading).then((res: any) => {
      loading.close();
      this.subData = res;
    });
  }
  private getQuzhiOptions(t: any) {
    const params: any = {
      params: {
        type: t,
        search: "",
      },
    };
    SearchDaohang(this, params).then((res: any) => {
      this.quzhis = res;
    });
  }
}
